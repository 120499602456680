import store from '@/store'
import firebase from "firebase/compat/app"

export default {
    login(email, password) {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
                store.dispatch('user/getAdminUserByEmail', result.user.email).then(user => {
                    localStorage.setItem('userData', JSON.stringify(user))
                    resolve(true)
                }).catch(() => {
                    firebase.auth().signOut()
                    reject(false)
                })
            }).catch(() => {
                reject(false)
            })
        })

    },
}
